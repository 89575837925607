import styled from 'styled-components';

const T1 = styled.div`
    font-family: Circular;
    font-size: 90px;
    color: #333333;
    @media (max-width: 800px) {
        font-size: 50px;
    }
`;

export default T1;